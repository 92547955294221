export default {
  black: '#000',
  white: '#fff',
  bg: '#E5E4D7',
  red: '#D54300',
  sisal: '#CFC8B8',
  nobel: '#B6B6B6',
  tuatara: '#272726',
  orange: '#D54300',

  // NOTE - Below colors is just in brands pages
  doveGray: '#686868',
  charm: '#D77183',
  celery: '#A6BD5C',
  trendyPink: '#81558F',

  // TODO - Change to sisal later
  bgBold: '#CFC8B8',

  // TODO - Check later
  blackOpacity: '#0000000f',
  bgSkeleton: '#F3F4F6',
  blackButtonSwitch: '#0000005a'
}
