import axios from 'axios'
import { BACKEND_URL_STORE } from '@constants/server.constant'

export const listSaleChannelsByRegionId = async (regionId: string) => {
  const { data } = await axios.get(
    `${BACKEND_URL_STORE}/region-sale-channel/get-sale-channels/${regionId}`,
    {
      withCredentials: true
    }
  )
  return data
}
